@import '../../resources/scss/mixins/mixins';

.device-page {
    .ant-form {
        @include lt-md {
            .label-width-medium {
                .ant-form-item-label {
                    width: 104px;
                }
            }
        }

        @include gt-sm {
            .label-width-medium {
                .ant-form-item-label {
                    width: 102px;
                }
            }
        }
    }
}

.buttons-config {
    width: 155px;
    display: flex;
    margin-left: 12px;

    @include lt-md {
        width: 100%;
        margin-left: 0;
        text-align: right;
    }
}
