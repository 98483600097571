.roles-container {
  &__filter {
    padding: 12px;

    &-title {
      //margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      text-transform: capitalize;
    }

    &-search {
      margin-top: 12px;

      .ant-form {
        .ant-form-item {
          &-label {
            //margin-right: 4px;
            font-family: Roboto, sans-serif;
            font-size: 14px;
            font-weight: 400;

            @media screen and (max-width: 991px) {
              flex-basis: 80px;
            }
          }

          &-control {
            .form-input {
              border-radius: 4px;
              -moz-border-radius: 4px;
              -webkit-border-radius: 4px;
            }
          }
        }
      }

      .search-input {
        border-radius: 4px;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
      }
    }
  }

  &__data {
    padding: 12px;

    &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 16px;
      text-transform: capitalize;
    }

    &-list {
      .ant-table {
        //padding-bottom: 12px;
        .ant-table-thead {
          font-family: Roboto, sans-serif;
          font-weight: 500;

          > tr {
            > th {
              padding: 6px 12px !important;
              background-color: #F6F6F6;
              height: 30px;
            }
          }
        }

        .ant-table-tbody {
          > tr {
            > td {
              padding: 8px 12px;
              font-family: Roboto, sans-serif;
              font-weight: 400;
              font-size: 14px;
            }
          }
        }

        .role-record:hover td {
          background: #e5fce5 !important;
        }

        ::-webkit-scrollbar {
          width: 6px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 24px #e5fce5;
          border-radius: 10px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          border-radius: 8px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #707070;
        }
      }
    }
  }
}

.grand-permission-modal {
  .select-all-area {
    width: 100%;
    background-color: #F6F6F6;
    margin: 0 0 12px 0;
    padding: 12px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }
  .group-label {
    display: flex;
    justify-content: space-between;
    background-color: #F6F6F6;
    border-radius: 4px;
    padding: 6px;
  }
}
