// tooltip
.ant-tooltip-content {
    border-radius: 3px;

    .ant-tooltip-arrow-content {
        background: $primary-color;
    }

    .ant-tooltip-inner {
        background: $primary-color;
        min-height: 24px;
        padding: 0 8px;
        font-size: 12px;
        line-height: 24px;
    }
}

// buttons
%btn-disabled {
    border-color: #ebebeb;
    background: #f6f6f6;
    color: #b1b1b1;
}

.ant-btn {
    border-radius: 4px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    text-transform: capitalize;

    &:not(.ant-btn-dangerous) {
        color: $primary-color;
        border-color: $primary-color;

        &:hover {
            background: $primary-color;
            color: #fff;
        }
    }

    &.ant-btn-ghost {
        border-color: #c0c0c0;
        color: $text-dark-color;

        &:not([disabled]):hover {
            background-color: #707070;
            color: #fff;
        }

        &:disabled {
            @extend %btn-disabled;

            &:hover {
                @extend %btn-disabled;
            }
        }
    }

    &.ant-btn-dangerous {
        &.ant-btn-primary {
            &:not([disabled]) {
                color: #ffffff;
                background-color: #ff4d4f;
                border: 1px solid transparent;

                &:hover {
                    background: #ff4d4f;
                    box-shadow: 0 0 0 2px #ffc7cd;
                }
            }
        }
    }

    &:disabled {
        @extend %btn-disabled;

        &:hover {
            @extend %btn-disabled;
        }
    }

    &.ant-btn-link {
        border-color: transparent;
        color: $primary-color;

        &:disabled {
            background-color: #ffffff;
            color: #c0c0c0;
            border-color: #ffffff;
        }
    }

    &.ant-btn-link {
        &:not([disabled]):hover {
            color: $primary-color;
            background-color: transparent;
        }
    }

    &.ant-btn-text {
        border-color: transparent;
        background: transparent;
        color: #5e5e5e;
    }

    &.ant-btn-text {
        &:not([disabled]):hover {
            color: $primary-color;
            background-color: transparent;
        }
    }

    &.ant-btn-primary {
        &:not(:disabled, .blue-btn, .light-blue-btn, .light-green-btn, .yellow-btn, .ant-btn-dangerous) {
            color: #fff;
            background: $primary-color;

            &:hover {
                box-shadow: 0 0 0 3px #cff1ce;
            }
        }
    }
}

%border-style {
    border-color: #c0c0c0;
    border-radius: 4px;
}

// input
.ant-form-item-label {
    font-size: 12px;

    label {
        //color: $text-dark-color2;
        color: $text-secondary-color;

        &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
            @media screen and (max-width: 1024px) {
                right: -15px;
            }

            @media screen and (min-width: 1025px) {
                right: -5px;
            }

            position: absolute;
            top: 6px;
            margin-right: 12px;
        }

        &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
            @media screen and (max-width: 1024px) and (min-width: 768px) {
                margin: 0 4px 0 2px;
            }

            @media screen and (min-width: 1025px) {
                margin: 0 14px 0 2px;
            }
        }
    }
}

.ant-form-vertical {
    .ant-form-item-label label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        top: 2px;
    }
}

.ant-input,
.ant-input-number {
    @extend %border-style;
}

.ant-input {
    &-affix-wrapper {
        @extend %border-style;
    }
}

// select
.ant-select {
    &:not(.ant-select-customize-input) .ant-select-selector {
        @extend %border-style;
    }

    &:not(.ant-pagination-options-size-changer, .ant-select-multiple) {
        .ant-select-selection-item {
            display: inline-block;
            width: 100px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }
    }
}

// picker
.ant-picker {
    @extend %border-style;
}

// input group
.ant-input-group {
    &.ant-input-group-compact {
        flex-wrap: nowrap;
        display: flex;

        > div {
            border-color: #c0c0c0;
        }

        .site-input-split {
            border: 1px solid #c0c0c0 !important;
        }

        div:first-child {
            @extend %border-style;
            border-radius: 4px 0 0 4px;
        }

        div:last-child {
            @extend %border-style;
            border-radius: 0 4px 4px 0;
        }

        &.ant-input-group-compact > .ant-select:first-child > .ant-select-selector {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
    }
}

// badge
.ant-badge {
    .ant-badge-count {
        padding: 0 8px;
        background: $primary-color;
    }

    .ant-badge-count-sm {
        height: 18px;
        font-size: 12px;
        line-height: 18px;
        border-radius: 9px;
    }
}

// modal
.ant-modal {
    $padding-modal: 24px;

    &:not(.ant-modal-confirm) {
        .ant-modal-content {
            border-radius: 4px;
            max-height: 85vh;
            overflow: hidden;

            .ant-modal-close-x {
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #191919;
            }

            .ant-modal-header {
                padding: $padding-modal 36px $padding-modal 0;
                margin-left: $padding-modal;
                margin-right: $padding-modal;

                .ant-modal-title {
                    font-size: 20px;
                    text-transform: capitalize;
                }
            }

            .ant-modal-body {
                padding: $padding-modal;
                overflow: auto;
                max-height: calc(85vh - $padding-modal * 6);
                min-height: 56px;
            }

            .ant-modal-footer {
                padding: $padding-modal 0;
                margin-left: $padding-modal;
                margin-right: $padding-modal;
                border-color: #f1f1f1;
            }
        }

        &.body-pd-t-0 {
            .ant-modal-body {
                padding-top: 0;
            }
        }
    }

    &.ant-modal-confirm {
        .ant-modal-close {
            top: 10px;
            right: 8px;
        }

        .ant-modal-content {
            border-radius: 4px;
        }
        .ant-modal-body {
            padding: 24px;
        }

        .ant-modal-confirm-btns {
            margin-top: 16px;
        }
    }
}

// form
.ant-form-item {
    margin-bottom: 12px;
}

.ant-form {
    @include xs {
        .ant-form-item-label > label::after {
            content: ':';
            position: relative;
            top: -0.5px;
            margin: 0 8px 0 2px;
            display: block;
        }
    }

    .ant-form-item-label > label::after {
        content: ':';
        position: relative;
        top: -0.5px;
        margin: 0 4px 0 2px;
        display: block;
    }

    &.bottom-item-12 {
        .ant-form-item {
            margin-bottom: 12px;
        }
    }

    &.bottom-item-8 {
        .ant-form-item {
            margin-bottom: 8px;
        }
    }

    .label-align-center {
        display: flex;
        align-items: center;
    }

    @include lt-md {
        .label-width-medium,
        .label-width-large-medium,
        .label-width-large {
            .ant-form-item-label,
            .ant-form-item-control {
                flex: unset;
            }

            .ant-form-item-control {
                flex: 1;
            }
        }
    }

    @include lt-md {
        .ant-form-item-label {
            padding-bottom: 0;
        }
    }
}

// radio
.ant-radio-wrapper {
    color: $text-dark-color;

    @include lt-md {
        span {
            padding-left: 2px;
        }
    }

    .ant-radio-group {
        padding-left: 2px;
    }
}

// placeholder
::-webkit-input-placeholder {
    /* Edge */
    color: #b1b1b1 !important;
    font-weight: 400 !important;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b1b1b1 !important;
    font-weight: 400 !important;
}

::placeholder {
    color: #b1b1b1 !important;
    font-weight: 400 !important;
}

//typography
div.ant-typography {
    margin-bottom: 0;

    a {
        color: $info-color;
    }
}

a {
    color: $info-color;
}

// ant-pagination
.ant-pagination {
    .ant-pagination-item-link,
    .ant-pagination-item {
        border-radius: 4px;
    }

    .ant-select-item-option-content {
        text-align: center;
    }

    .ant-pagination-item-active {
        background: $primary-color;

        a {
            color: #fff;
        }

        @media only screen and (max-width: 576px) {
            .ant-pagination-options {
                display: inline-block;
            }
        }
    }
}

//range picker
.ant-picker-range {
    @include xs {
        width: 220px;
    }
}

//ant-tag
.ant-tag {
    margin-right: 0;
    border-radius: 79px;
    -moz-border-radius: 79px;
    -webkit-border-radius: 79px;
    color: #ffffff;
    border: 1px solid transparent;

    &.ant-tag-success {
        background-color: #4dd69c;
    }

    &.ant-tag-error {
        background-color: #ff4559;
    }

    &.ant-tag-warning {
        background-color: #fdb924;
    }

    &.ant-tag-default {
        background-color: #339dff;
    }
}

//ant-form {
.ant-form {
    .ant-form-item-label {
        label {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
        }
    }

    &.horizontal-form {
        @media (max-width: 575px) {
            .ant-form-item {
                .ant-form-item-label {
                    flex: initial;
                }

                .ant-form-item-control {
                    flex: 1;
                }
            }
        }
    }
}

//ant-checkbox
.ant-checkbox {
    &-wrapper {
        font-size: 14px;
        font-weight: 400;
        font-family: Roboto, sans-serif;
    }

    .ant-checkbox-input,
    .ant-checkbox-inner {
        width: 16px;
        height: 16px;
        border-radius: 3px;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
    }
}

.ant-breadcrumb a {
    color: #191919;
}

// card
.ant-card {
    .ant-card-head {
        padding: 0 12px;

        @include lt-md {
            padding: 0 8px;
        }
    }

    .ant-card-body {
        canvas {
            max-width: 100%;
        }
    }
}

body {
    ::-webkit-scrollbar {
        width: 8px;
        height: 4px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 10px;
    }
}

@media only screen and (max-width: 576px) {
    .ant-pagination-options {
        display: inline-block;
    }
}

.ant-breadcrumb,
.breadcrumb-header {
    text-transform: capitalize;
    font-size: 14px;
    color: #7d7e7e;

    @include lt-md {
        .ant-breadcrumb-separator {
            display: none !important;
        }
    }
}

.ant-list-split {
    .ant-list-item {
        border-bottom: 1px solid #ebebeb;

        &:last-child {
            border-bottom: 1px solid #ebebeb;
        }
    }
}

.ant-skeleton {
    .ant-skeleton-content {
        .ant-skeleton-title + .ant-skeleton-paragraph {
            margin-top: 12px;
            margin-bottom: 12px;
        }
    }
}

.ant-tabs {
    &.ant-tabs-top > .ant-tabs-nav {
        margin: 0;

        &:before {
            display: none;
        }
    }

    .ant-tabs-nav-wrap {
        height: 32px;
    }

    .ant-tabs-tab {
        padding: 4px 12px;
        font-weight: 500;
        border-bottom: 1px solid #f0f0f0;

        .ant-tabs-tab-btn {
            display: flex;
            align-items: center;
        }

        &:not(.ant-tabs-tab-active) {
            color: $text-secondary2-color;

            .ant-badge .ant-badge-count {
                background: #f6f6f6;
                color: $text-secondary-color;
            }
        }
    }

    .ant-tabs-tab + .ant-tabs-tab {
        margin: 0;
    }
}

.ant-alert,
.ant-alert-warning {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 8px;
    gap: 2px;
    height: 32px;
    background: #fff9ea;
    border: 1px solid #fdb924;
    border-radius: 4px;
    -webkit-border-radius: 4px;

    @media screen and (max-width: 512px) {
        height: 50px;
    }

    .ant-alert-close-icon > span,
    .ant-alert-content > .ant-alert-message {
        color: #fdb924;
        font-weight: 300;
        font-size: 14px;
    }
}

.ant-checkbox-disabled + span {
    color: #7d7e7e;
}

.ant-divider {
    border-color: #f1f1f1;
}

.ant-divider-horizontal {
    margin: 12px 0;
}

.ant-select-auto-complete {
    width: 100% !important;
}
