$yellow: #FDB924;
$light-green: #4DD69C;
$blue: #3340B6;
$light-blue: #339dff;
$danger: $text-error-color;

%yellow {
  background-color: $yellow;
  border: none;
}

%light-green {
  background-color: $light-green;
  border: none;
}

%blue {
  background-color: $blue;
  border: none;
}

%light-blue {
  background-color: $light-blue;
  border: none;
}

%danger {
  background-color: $danger;
  border: none;
}

.yellow-btn {
  color: #fff;
  @extend %yellow;

  &:hover {
    @extend %yellow;
  }
}


.light-green-btn {
  color: #fff;
  @extend %light-green;

  &:hover {
    @extend %light-green;
  }
}

.blue-btn {
  color: #fff;
  @extend %blue;

  &:hover {
    @extend %blue;
  }
}

.light-blue-btn {
  color: #fff;
  @extend %light-blue;

  &:hover,
  &:active {
    @extend %light-blue;
  }
}

.danger-btn {
  color: #fff;
  @extend %danger;

  &:hover,
  &:active {
    @extend %danger;
  }
}


.icon-btn {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  padding: 0;
  font-size: 10px;
}

.ant-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  >i[class*="pd-"] {
    padding-right: 0 !important;
  }

  >i[class*="mg-"] {
    margin-right: 0 !important;
  }
}

.link-span {
  cursor: pointer;
  color: #1A1A1A;

  &:hover {
    color: $primary-color;
  }
}