.account-owner-item {
  margin-bottom: 15px;
  overflow: hidden;
  &:last-child {
    margin-bottom: 0;
  }
  .account-owner-avatar {
    width: 30px;
    height: 30px;
    object-fit: cover;
    object-position: center center;
    display: inline-block;
    margin-right: 10px;
  }
  .account-owner-name {
    display: inline-block;
  }
  .account-owner-icon-remove {
    margin-top: 7px;
  }
}
.account-info {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05), 0 7px 24px rgba(17, 17, 17, 0.08);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  margin-bottom: 12px;

  .ant-card {
    &-head  {
      .ant-card-head-title {
        display: flex;
        align-items: center;
        padding: 12px 0;
      }
      .ant-card-extra {
        padding: 0;
        > button {
          display: flex;
          align-items: center;
        }
      }
    }
    &-body {
      padding: 12px;
    }
  }

  &-form {
    .ant-form-item {
      &-label {
        display: inline-block;
        min-width: 200px;
        > label {
          color :#5A5A5A;
          font-size: 14px;
        }
      }
      &-control {
        &-input-content {
          font-weight: 500;
        }
      }
    }
  }

  .ant-avatar {
    border-radius: 4px;
    border: 1px solid transparent;
  }

  .user-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .delete-btn {
      float: right;
      border-radius: 4px;
      -moz-border-radius: 4px;
      -webkit-border-radius: 4px;
      padding:4px 10px;
      border: 1px solid #E53D4F;
      color: #E53D4F;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        color: #FFFFFF;
        background-color: #E53D4F;
      }
    }
  }

  .btn-code-check {
    @include xs {
      margin-top: 8px;
    }

    @include gt-xs {
      margin-left: 4px;
    }
  }
}
