.confirm-delete-modal {
  .ant-modal-content {
    .ant-modal {
      &-header {
      }
      &-body {
        display: flex;
        align-items: center;
      }
      &-footer {
        border-top: none;
        padding-top: 0!important;
      }
    }
  }
}
