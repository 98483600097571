html {
    overflow-x: hidden;
    overflow-y:auto;
    //height: 100%;
    background: #f0f2f5;
}

.ant-layout {
    //height: 100vh;
    height: 100vh;
    overflow: auto;
    z-index: 1;
}

.main-body {
    height: 100%;

}

.main-layout {
    .sidebar {
        min-height: 100vh;
        background: $primary-color;
        position: fixed;
        z-index: 99;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;


        .ant-layout-sider-children {
            height: calc(100vh - 48px);
            overflow-x: hidden;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .collapseBtn {
            text-align: right;
            height: 68px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);

            .ant-btn {
                color: #fff;
                font-size: 12px;
                transition: all 0.5s;
                width: 48px;
            }
        }

        .ant-menu {
            border: none;

            .ant-menu-item {
                width: 100%;
                margin: 0;
                height: 48px;
                display: flex;
                align-items: center;
                padding-left: 16px !important;

                &:hover {
                    background: #d0e9d0;

                    .sidebar-item,
                    i {
                        color: $primary-color !important;
                    }
                }

                &.logout {
                    position: absolute;
                    bottom: 0px;
                    border-top: 1px solid rgba(255, 255, 255, 0.3);
                }

                &::after {
                    border: none;
                }
            }

            .ant-menu-submenu-title {
                &:hover {
                    background: #d0e9d0;

                    .ant-menu-title-content,
                    i {
                        color: $primary-color !important;
                    }
                }
            }

            .ant-menu-item-selected {
                background-color: #fff;
                color: $primary-color;

                i {
                    color: $primary-color;
                }
                .sidebar-item {
                    color: $primary-color;
                    font-weight: 500;
                }
            }
        }
        .sidebar-menu,
        .ant-menu-sub {
            background: $primary-color;
        }

        .logo {
            width: 100%;
            padding: 20px 10px 10px 25px;
            height: 64px;
            box-sizing: border-box;
            background-color: #002040;

            img {
                width: 80px;
                height: 25px;
            }

            &.collapsed {
                img {
                    width: 27px;
                    height: 25px;
                }
            }
        }

        .sidebar-item {
            color: #fff;
            cursor: pointer;
            text-decoration: none;
            text-transform: capitalize;

            &:hover {
                color: #fff;
            }
            &-has-children {
                .ant-menu-title-content {
                    color: #fff;

                    &:hover {
                        color: $primary-color;
                    }
                }
            }
        }

        i {
            color: #fff;
            font-size: 12px !important;
        }

        &.ant-layout-sider-collapsed {
            left: -58px;

            @include gt-xs {
                left: 0;
            }

            .ant-menu-inline-collapsed {
                .ant-menu-item {
                    padding: 0 !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        background: #d0e9d0;
                        .sidebar-item > i {
                            color: $primary-color;
                        }
                    }
                }
                ._sidebar_menu_payment_voucher {
                    text-align: center;
                }

                .sidebar-item__url {
                    display: none;
                }

                .ant-menu-submenu-selected {
                    background-color: #fff;
                    color: $primary-color;
    
                    i, .ant-menu-title-content {
                        color: $primary-color;
                    }
                }
            }
        }

        .sidebar-item-has-children {
            .ant-menu-submenu-title {
                padding-left: 16px !important;
                min-height: 48px;
                margin: 0;
                display: flex;
                align-items: center;
            }
        }
    }

    .header {
        background-color: #fff;
        padding: 0;

        .header-inner {
            position: relative;
            height: 64px;
            padding: 0;
            background: #fff;
            box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
            width: 100%;
            .header-inner-inner {
                padding: 0 12px;
                height: 100%;
            }
        }

        .trigger {
            font-size: 22px;
            cursor: pointer;
        }

        .right-header {
            display: flex;
            justify-content: space-between;
            height:100%;
        }

        .selector-fund {
            @include lt-sm {
                flex: 1;
            }

            .ant-select {
                min-width: 250px;

                @include lt-sm {
                    width: 100%;
                }
            }
        }
    }

    .ant-layout {
        @include gt-xs {
            padding-left: 48px;
        }
    }

    .main-content {
        background-color: $main-background-color;
        padding-bottom: 0.1px;

        .bread-crumb-container {
            background-color: #fff;
            padding: 15px 30px 12px;
        }

        .white-box {
            background-color: #fff;
            margin: 12px;
            border-radius: 4px;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.05), 0 7px 24px rgba(17, 17, 17, 0.08);

            &--padding-15px {
                padding: 15px;
            }

            &--no-margin {
                margin: 0;
            }
        }

        .tab-pane-inner-box {
            padding: 0 20px 20px 20px;
        }

        .h-line {
            border-bottom: 1px solid #ccc;
        }

        .loading-container {
            text-align: center;
            padding: 30px;
        }
    }
}

.ant-tooltip,
.ant-menu-submenu {
    .sidebar-item {
        color: #ccc;
        cursor: pointer;
        text-decoration: none;
        margin-left: 5px;
        &:hover {
            color: #fff;
        }
        &__url {
            a {
                color: #fff;
            }
        }
    }
}

.ant-tooltip {
    ._link-menu-dashboard {
        color: #fff;
        text-transform: capitalize;
    }
}

.ant-menu-submenu-popup {
    .ant-menu-sub {
        background-color: $primary-color;
        .ant-menu-item {
            &:hover {
                background: #d0e9d0;
                .sidebar-item,
                i {
                    color: $primary-color !important;
                }
            }
        }
        ._link-menu-dashboard {
            color: #fff;
        }

        .ant-menu-item-selected {
            background-color: #fff;

            ._link-menu-dashboard {
                color: $primary-color;
            }
        }
    }
}

.ant-menu-inline-collapsed-tooltip {
    .ant-tooltip-inner {
        border-radius: 3px;
        padding: 2px 8px;

        ._link-menu-dashboard {
            margin-left: 0;

            > i {
                display: none;
            }

            .sidebar-item__url {
                padding: 0;
            }
        }
    }
}
