.payment-details {
  .ant-card {
    -webkit-border-radius: 4px;
    background: #FFFFFF;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05), 0 7px 24px rgba(17, 17, 17, 0.08);
    border-radius: 4px;

    &-head {
      .ant-card {
        &-head-title, &-extra {
          padding: 12px 0;
          @media screen and (max-width: 1280px ) {
            font-size: 14px;
          }
        }
      }

      &-wrapper {
        @media screen and (max-width: 1376px){
          display: inline-block;

          .ant-card-head-title {
            padding: 12px 0 0 0 ;
          }
          .ant-card-extra {
            padding-top: 12px;
            .ant-row {
              margin-left: -12px!important;
            }
          }
        }
      }
    }

    &-body {
      padding: 12px;
    }

  }

  .payment-infos {
    .ant-form-item-label {
      > label {
        color: #5A5A5A;
      }
      min-width: 135px;
    }
  }


}
.ant-drawer {
  .ant-drawer-content {
    .ant-drawer-wrapper-body {
      .ant-drawer {
        &-header {
          padding: 12px;
          .ant-drawer-title {
            font-size: 20px;
            color: $primary-color;
            text-transform: capitalize;
          }
        }
        &-body {
          padding: 12px;

          .ant-table .ant-table-thead > tr > th {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), $primary-color;
          }
          .ant-table .ant-table-tbody > tr > td {
            border-color: #f1f1f1;
            padding: 12px;
          }
        }
      }
    }
  }
}
