.staff-detail-img-cover {
  width: 150px;
  height: 150px;

  @media only screen and (min-width: 1200px) {
    width: 180px;
    height: 180px;
  }
}

.staff-detail-info-label {
  //@media only screen and (min-width: 1200px) {
  width: 120px;
  display: inline-block;

  @media screen and (max-width: 1024px) and (min-width: 992px) {
    width: 95px;
  }

  @media screen and (max-width: 1073px) and (min-width: 1025px) {
    width: 95px;
  }

  @media screen and (max-width: 425px) {
    width: 75px;
  }
}

.staff-detail-info-content {
  font-weight: 500;
  word-break: break-all;
  white-space: pre-wrap;
}

.staff-detail-container {

  &__staff-card,
  &__account-list {
    margin-right: 0 !important;

    @media screen and (max-width: 768px) {
      margin-right: 12px !important;
    }

    .ant-card {
      color: #1A1A1A;

      &-head {
        padding: 0;
        border-bottom: none;

        &-title {
          padding: 12px 12px 0 12px;
          font-weight: 500;
          font-size: 16px;
          font-family: Roboto, sans-serif;
          color: #1A1A1A;

          a,
          div {
            color: #1A1A1A;

            &:hover div {
              color: $primary-color;
            }
          }
        }
      }

      &-body {
        padding: 12px;

        .staff-info {
          align-self: center;
          //align-items: center;
          justify-content: center;
        }
      }
    }
  }

  &__account-list {
    margin-left: 0;

    @media screen and (max-width: 768px) {
      margin-left: 12px;
      margin-top: 0 !important;
    }

    .ant-table {
      &-thead {
        font-family: Roboto, sans-serif;
        font-weight: 500;
        height: 30px;

        tr>th {
          padding: 6px;
          background-color: #F6F6F6;
          color: #1A1A1A;
        }
      }

      &-tbody {
        tr>td {
          padding: 12px 6px 6px 6px;
          font-family: Roboto, sans-serif;
          font-size: 14px;
          font-weight: 400;
          color: #1A1A1A;

          .ant-select>.ant-select-selector {
            height: 32px;

            .ant-select-selection-placeholder,
            .ant-select-selection-item,
            .ant-select-selection-search {
              display: flex;
              align-items: center;
            }
          }
        }
      }

      .account-record:hover td {
        background: #e5fce5 !important;
      }

      ::-webkit-scrollbar {
        width: 6px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 24px #e5fce5;
        border-radius: 10px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        border-radius: 8px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #707070;
      }
    }
  }

}

.role-row {
  display: grid;
  grid-template-columns: 150px auto;
  gap: 16px;
  align-items: center;

  @include gt-sm {
    grid-template-columns: 230px auto;
  }
}

.role-title {
  border-radius: 4px 4px 0px 0px;
  background: var(--greyscale-lighter, #F6F6F6);
  margin-bottom: 12px;
  padding: 6px 12px;
}



.select-container {
  position: relative;

  .clear-icon {
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #FFF;
    background: #7D7E7E;
    opacity: 0;
    transition: color 0.3s ease, opacity 0.15s ease;
    width: 14px;
    height: 14px;
    border-radius: 14px;
    font-size: 10px;
    cursor: pointer;
  }

  &:hover {
    .clear-icon {
      opacity: 1;
    }
  }

  .ant-select-multiple .ant-select-selection-item-remove > .anticon {
    vertical-align: -0.1em;
  }
}