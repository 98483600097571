.ant-notification-notice{
  max-width: 400px;
  margin-left: auto;
  padding: 12px 12px;
  overflow: hidden;
  word-wrap: break-word;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);

  @include lt-md {
    max-width: 300px;
  }
}

.ant-notification-notice-success {
  @extend .ant-notification-notice;
  .ant-notification-notice {
    &-content {
      border-left: 2px solid #4DD69C;
    }

    &-with-icon {
      .ant-notification-notice-icon {
        position: absolute;
        display: flex;
        align-items: center;
        bottom: 25%;
        top:25%;
        margin-left: 12px;
        font-size: 24px;
        color: #4DD69C;
      }

      .ant-notification-notice-message {
        font-size: 16px;
        font-weight: 500;
      }

      .ant-notification-notice-description {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.ant-notification-notice-error {
  @extend .ant-notification-notice;
  .ant-notification-notice {
    &-content {
      border-left: 2px solid #FF4559;
    }

    &-with-icon {
      .ant-notification-notice-icon {
        position: absolute;
        display: flex;
        align-items: center;
        bottom: 25%;
        top:25%;
        margin-left: 12px;
        font-size: 24px;
        color: #FF4559;
      }

      .ant-notification-notice-message {
        font-size: 16px;
        font-weight: 500;
      }

      .ant-notification-notice-description {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.ant-notification-notice-warning {
  @extend .ant-notification-notice;
  .ant-notification-notice {
    &-content {
      border-left: 2px solid #FDB924;
    }

    &-with-icon {
      .ant-notification-notice-icon {
        position: absolute;
        display: flex;
        align-items: center;
        bottom: 25%;
        top:25%;
        margin-left: 12px;
        font-size: 24px;
        color: #FDB924;
      }

      .ant-notification-notice-message {
        font-size: 16px;
        font-weight: 500;
      }

      .ant-notification-notice-description {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
