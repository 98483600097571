.account-item {
  display: flex;
  flex-direction: column;

  &:hover .head, &:hover .body, &:hover .account-item__last_settlement {
    background-color: #E5FCE5;
  }

  .head {
    display: flex;
    align-items: center;
    padding: 12px 12px 0 12px;
  }

  .body {
    padding: 0 12px;
    min-height: 101px;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-top: 1px solid #F1F1F1;
  }

  &__name {
    display: flex;
    align-items: center;
  }

  .icon-active {
    top: 16px;
  }

  &__bank_info {
    margin-top: 12px;
    line-height: 24px;
    .row {
      width: 100%;
      display: flex;
      .label {
        min-width: 100px;
      }
    }
  }

  &__last_settlement {
    padding: 0 12px 4px 12px;
    font-size: 12px;
    font-style: italic;
    color:#5A5A5A;
  }

  &__description {
    margin-right: 5px;
  }

  &__bottom {
    &__currency {
      border-top-right-radius: 6px;
      border-bottom-left-radius: 6px;
      background-color: $primary-color;
      color: #FFF;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      font-weight: bold;
    }

    &__actions {
      padding: 8px 12px;
    }
  }
}

.account-filter {
  .white-box {
    margin: 0 !important;
  }

  .search {
    margin: 0 !important;

    .title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      button {
        color: $text-dark-color;
      }
    }

    .ant-form-item-label {
      label {
        padding-right: 8px;
      }
    }
    @media screen and (max-width: 1643px) and (min-width: 992px) {
      span.ant-radio + * {
        padding-right: 2px;
        padding-left: 4px;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 769px) {
      .ant-radio-group, .ant-radio-group-outline {
        width: 107%;
        margin-left: -5px;
      }
    }

    @media screen and (max-width: 1599px) and (min-width: 1420px) {
      .ant-col-xl-10 {
        display: block;
        flex: 0 0 35%;
        max-width: 35%;
      }
      .ant-col-xl-11 {
        display: block;
        flex: 0 0 37%;
        max-width: 37%;
      }
      .ant-col-xl-9 {
        display: block;
        flex: 0 0 28%;
        max-width: 28%;
      }
    }

    @media screen and (min-width: 1420px) {
      .expand-btn > span {
        display: none;
      }
    }

    .ant-radio-group {
      @media screen and (max-width: 768px) {
        line-height: 30px;
      }
    }

  }

  .table-header {
    margin: 12px 0 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include lt-sm {
      flex-direction: column;
      align-items: flex-start;

      button {
        margin-top: 12px;
      }
    }
  }
}

.account-list {
  &-header {
    background-color: #FFFFFF;
    margin: 12px 0 0 0;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include lt-md {
      flex-direction: column;
      align-items: flex-start;

      .left-side {
        justify-content: space-between;
        width: 100%;
      }

      button {
        margin-top: 8px;
      }
    }

    .left-side {
      display: flex;
      align-items: center;

      .switch {
        margin-left: 8px;
        font-size: 20px;
        display: inherit;
        align-items: center;
        padding-top: 4px;
        min-width: 50px;
        justify-content: space-between;
        cursor: pointer;

        > span {
          padding: 0 8px;
          border-bottom: 1px solid #B1B1B1;
          color: #B1B1B1;
        }

        .selected {
          border-bottom: 1px solid $primary-color;
          color: $primary-color;
        }
      }
    }
  }

  &-view-list {
    background-color: #FFFFFF;
    padding: 0 12px 12px 12px;
    width: 100%;

    .ant-table {
      &-tbody {
        > tr > td {
          cursor: pointer;
        }
      }

      &-pagination {
        padding-right: 12px;
      }
    }

    .ant-table-container {
      padding: 0;
    }

    .account-field {
      display: flex;
      flex-direction: row;

      .name {
        color: #339DFF;
        @media screen and (min-width: 1441px) {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
      .divine {
        height: 16px;
        @media screen and (min-width: 1441px) {
          border-right: 1px solid #C4C4C4;
          margin-right: 6px;
        }
      }
    }

    .bank-field {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start !important;
    }

    .actions {
      display: none;
      flex-direction: column;
      justify-content: space-evenly;
      width: 24px;

      > span {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        //margin-bottom: 6px;
      }
    }
    @media screen and (max-width: 768px) {
      .actions {
        display: block;
      }
    }

    .account-table-row {
      align-items: center;

      > td, td > div {
        min-height: 60px;
        align-items: center;
      }
    }

    .account-table-row:hover .actions {
      display: flex;
    }
  }
}
