.memo-container {
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;

  &__header {
    width: 100%;
    padding: 12px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    .ant-row {
      width: 100%;
    }
    @media screen and (max-width:425px) {
      .ant-col {
        display: flex;
         button {
          margin-top: 8px
        }
      }
    }
  }


  &__data {
    padding: 0 12px 12px 12px;

    .ant-table {
      padding-bottom: 12px;
      .ant-table-thead {
        font-family: Roboto, sans-serif;
        font-weight: 500;

        > tr {
          > th {
            padding: 6px 12px !important;
            background-color: #F6F6F6;
          }
        }
      }

      .ant-table-tbody {
        > tr {
          > td {
            padding: 8px 12px;
            font-family: Roboto, sans-serif;
            font-weight: 400;
            font-size: 14px;
            color:#1A1A1A;
          }
        }
      }

      .memo-record:hover td {
        background: #e5fce5 !important;
      }
    }
  }
}
