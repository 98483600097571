.transaction-quickView {
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 4px 0;
    padding: 4px;
    > div {
      flex-direction: column;
      align-items: flex-start;
      margin: 2px 0;
    }
    .date-picker {
      width: 100%;
      margin: 6px 0;
    }
  }
}

.sub-transaction-form {
  .ant-modal-header {
    border: none;
  }

  .ant-modal-body {
    padding: 0 12px !important;
  }

  .ant-card {
    border: 1px solid transparent;
    background-color: #F7F7F7;
    margin-bottom: 12px;
    border-radius: 4px;
    -webkit-border-radius: 4px;

    .ant-card-head {
      padding: 6px 12px;
      border-bottom: 1px solid transparent;

      .ant-card-head-title {
        padding: 0;
        color: $primary-color;
        font-size: 16px;
      }

      .ant-card-extra {
        padding: 0;

        > button, > button:disabled {
          padding: 0;
          background-color: transparent;
          border: 1px solid transparent;

          &:not(:disabled) {
            color: #FF4559;
          }
        }
      }
    }

    .ant-card-body {
      padding: 0 12px;
    }
  }

  .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    margin-bottom: 0;
    margin-left: 0;
  }

  .ant-modal-footer {
    border: none;
  }
}

.sub-transactions-box {
  .ant-table {
    border-radius: 4px 4px 0 0;

    table {
      border-spacing: 0!important;
    };

    .ant-table-thead > tr > th {
      background: #EBEBEB;
      color: #1a1a1a;
      padding: 6px 12px;
      white-space: nowrap;
      font-size: 12px;
      font-weight: 500;
      border-radius: 0;

      @media screen and (min-width: 992px) and (max-width: 1281px) {
        padding: 5px 8px;
      }
    }

    .ant-table-tbody > tr > td {
      padding: 16px 12px;
      white-space: nowrap;
      border-top: 1px solid transparent!important;
      border-bottom: 1px solid ;
      //background-color: #FFFFFF;
      background-color: #F8F8F8;

      .ant-table-tbody {
        .ant-table-measure-row {
          display: none;
        }
      }

      @media screen and (min-width: 992px) and (max-width: 1281px) {
        padding: 8px 6px;
      }
    }

  }
}

.active-row-key {
  border: 1px solid #f6f6f6;
  > td {
    background:#F6F6F6;
  }

  .sub-transactions-box {
    border-radius: 0 0 6px 6px;

    .ant-table-wrapper::before, .ant-table-wrapper::after {
      display:none
    }
  }
}

.transaction-focus-row {
  & > td, > {
    background-color: $hover-row-color;
  }
}

.row-has-error {
  & > td {
    //background-color: #F6F6F6;
    border-top: 1px solid #FFEDEF !important;
  }
  & > td:nth-child(1) {
    border-left: 1px solid #FFEDEF !important;
    -webkit-border-radius: 6px 0 0 0;
  }
  & > td:last-child {
    border-right: 1px solid #FFEDEF !important;
    -webkit-border-radius: 0 6px 0 0;
  }
}

