/*=====================================COMMON CLASS=====================================*/
/* PADDING */
$class: 'pd-';

@for $i from 0 through 60 {
    // for each $pd-t-#{i}
    .#{$class}#{$i} {
        padding: #{$i}px;
    }
}

/* PADDING TOP */
$class: 'pd-t-';

@for $i from 0 through 60 {
    // for each $pd-t-#{i}
    .#{$class}#{$i} {
        padding-top: #{$i}px;
    }
}

/* PADDING RIGHT */
$class: 'pd-r-';

@for $i from 0 through 60 {
    // for each $pd-t-#{i}
    .#{$class}#{$i} {
        padding-right: #{$i}px;
    }
}

/* PADDING BOTTOM */
$class: 'pd-bt-';

@for $i from 0 through 60 {
    // for each $pd-t-#{i}
    .#{$class}#{$i} {
        padding-bottom: #{$i}px;
    }
}

/* PADDING LEFT */
$class: 'pd-l-';

@for $i from 0 through 60 {
    // for each $pd-t-#{i}
    .#{$class}#{$i} {
        padding-left: #{$i}px;
    }
}

/* PADDING TOP BOTTOM */
$class: 'py-';

@for $i from 0 through 60 {
    // for each $pd-t-#{i}
    .#{$class}#{$i} {
        padding-top: #{$i}px;
        padding-bottom: #{$i}px;
    }
}

/* PADDING LEFT RIGHT */
$class: 'px-';

@for $i from 0 through 60 {
    // for each $pd-t-#{i}
    .#{$class}#{$i} {
        padding-left: #{$i}px;
        padding-right: #{$i}px;
    }
}

$class: 'mg-';

@for $i from 0 through 60 {
    // for each $pd-t-#{i}
    .#{$class}#{$i} {
        margin: #{$i}px;
    }
}

$class: 'mg-t-';

@for $i from 0 through 60 {
    // for each $pd-t-#{i}
    .#{$class}#{$i} {
        margin-top: #{$i}px;
    }
}

$class: 'mg-r-';

@for $i from 0 through 60 {
    // for each $pd-t-#{i}
    .#{$class}#{$i} {
        margin-right: #{$i}px;
    }
}

$class: 'mg-bt-';

@for $i from 0 through 60 {
    // for each $pd-t-#{i}
    .#{$class}#{$i} {
        margin-bottom: #{$i}px;
    }
}

$class: 'mg-l-';

@for $i from 0 through 60 {
    // for each $pd-t-#{i}
    .#{$class}#{$i} {
        margin-left: #{$i}px;
    }
}

$class: 'mx-';

@for $i from 0 through 60 {
    // for each $pd-t-#{i}
    .#{$class}#{$i} {
        margin-left: #{$i}px;
        margin-right: #{$i}px;
    }
}

$class: 'my-';

@for $i from 0 through 60 {
    // for each $pd-t-#{i}
    .#{$class}#{$i} {
        margin-top: #{$i}px;
        margin-bottom: #{$i}px;
    }
}

// border width
$class: 'border-';

@for $i from 0 through 60 {
    // for each $pd-t-#{i}
    .#{$class}#{$i} {
        border-width: #{$i}px;
    }
}

//font-size
$class: 'font-size-';

@for $i from 0 through 60 {
    // for each $pd-t-#{i}
    .#{$class}#{$i} {
        font-size: #{$i}px;
    }
}

//top
$class: 'top-';

@for $i from 0 through 60 {
    // for each $pd-t-#{i}
    .#{$class}#{$i} {
        top: #{$i}px;
    }
}

//top
$class: 'bottom-';

@for $i from 0 through 60 {
    // for each $pd-t-#{i}
    .#{$class}#{$i} {
        bottom: #{$i}px;
    }
}

//left
$class: 'bottom-';

@for $i from 0 through 60 {
    // for each $pd-t-#{i}
    .#{$class}#{$i} {
        bottom: #{$i}px;
    }
}

//left
$class: 'right-';

@for $i from 0 through 60 {
    // for each $pd-t-#{i}
    .#{$class}#{$i} {
        right: #{$i}px;
    }
}

.resize-none {
    resize: none;
}

.line-height-normal {
    line-height: normal;
}

.no-border {
    border: none;
}

.no-radius {
    border-radius: 0;
}

.not-allowed {
    cursor: not-allowed;
}

.pointer {
    cursor: pointer;
}

.flr {
    float: right;
}

.fll {
    float: left;
}

.clearfix {
    clear: both;
}

.text-yellow {
    color: #f39c12 !important;
}

.text-danger {
    color: #ff4d4f;
}

.text-blue {
    color: #339dff !important;
}

.text-white {
    color: #ffffff !important;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.hide {
    display: none;
}

.text-al-r {
    text-align: right;
}

.bold {
    font-weight: bold;
}

.font-medium {
    font-weight: 500;
}

.bold-400 {
    font-weight: 400;
}

.color-red {
    color: red;
}

.bg-red {
    background-color: red;
}

.bg-white {
    background-color: white;
}

.bg-grey {
    background-color: #efeeee;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.width-100pc {
    width: 100%;
}

.width-50pc {
    width: 50%;
}

.list-unstyled {
    margin: 0;
    padding: 0;
}

.font-size-12px {
    font-size: 12px;
}

.font-size-18px {
    font-size: 18px;
}

.font-size-20px {
    font-size: 20px;
}

.width-100px {
    width: 100px;
}

.width-150px {
    width: 150px;
}

.width-200px {
    width: 200px;
}

.width-270px {
    width: 270px;
}

.width-300px {
    width: 300px;
}

.table-responsive {
    overflow-x: auto;
    clear: both;
}

.v-text-top {
    vertical-align: text-top;
}

.d-block {
    display: block;
}

.d-inline-block {
    display: inline-block;
}

.d-none {
    display: none !important;
}

.nowrap-css {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 150px;
    display: inline-block;
}

.overflow-auto {
    overflow: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.font-weight-word {
    font-weight: 600;
}

.word-break {
    word-break: break-all;
}

.white-space-pre {
    white-space: pre;
}

.breadcrumb-wrap-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 300px;
    display: inline-block;
    position: relative;
    top: 4px;
}

.icon-quick-edit {
    font-size: 12px;
    color: #505050;
}

.icon-check-quick-edit {
    margin-left: 10px;
}

.blue-link {
    color: #1890ff;
}

.fix-css-tabs-nav {
    .ant-tabs-nav {
        padding-left: 20px;
    }
}

.rounded-full {
    border-radius: 9999px;
}

.rounded-sm {
    border-radius: 0.125rem;
}

.rounded {
    border-radius: 0.25rem;
}

.rounded-md {
    border-radius: 0.375rem;
}

// flex
.flex {
    display: flex;
}

.grid {
    display: grid;
}

// flex direction
.flex-row {
    flex-direction: row;
}

.flex-col {
    flex-direction: column;
}

.flex-hitem {
    flex: 1 1 0;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.flex-wrap {
    flex-wrap: wrap;
}

.align-center {
    display: flex;
    align-items: center;
}

.align-base-line {
    display: flex;
    align-items: baseline;
}

.md-space-between {
    @include lt-md {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.flex1 {
    flex: 1;
}

//justify content
.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.justify-evenly {
    justify-content: space-evenly;
}

//Justify Items
.justify-items-start {
    justify-items: flex-start;
}

.justify-items-end {
    justify-items: flex-end;
}

.justify-items-center {
    justify-items: center;
}

.justify-items-stretch {
    justify-items: stretch;
}

//Align Content
.content-center {
    align-content: center;
}

.content-start {
    align-content: flex-start;
}

.content-end {
    align-content: flex-end;
}

.content-between {
    align-content: space-between;
}

.content-around {
    align-content: space-around;
}

//Align Items
.items-start {
    align-items: flex-start;
}

.items-end {
    align-items: flex-end;
}

.items-center {
    align-items: center;
}

//Align Self
.self-auto {
    align-self: auto;
}

.self-start {
    align-self: flex-start;
}

.self-end {
    align-self: flex-end;
}

.self-center {
    align-self: center;
}

//border color
.border {
    border: #b3ceed 2px solid;
}

.icon-active {
    background-color: $text-cuccess-color;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    margin-left: 5px;
    margin-right: 10px;
    min-width: 16px;
}

.icon-inactive {
    background-color: $text-error-color;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    margin-left: 5px;
    margin-right: 10px;
    min-width: 16px;
}

//font-style

.italic {
    font-style: italic;
}

.cursor-pointer {
    cursor: pointer;
}

.h-200 {
    height: 200px;
}

.text-maxW-250 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 250px;
}

.text-maxW-300 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 300px;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.h-100pc {
    height: 100%;
}

.ant-spin-nested-loading {
    height: 100%;
}

.ant-spin-container {
    height: 100%;
}

.negative-money {
    color: $text-error-color;
}

.positive-money {
    //color: $text-cuccess-color;
    color: $primary-color;
}

.transaction-code {
    color: #1a1a1a;
}

.default-money-total {
    font-size: 1.2em;
}

.first-letter-uppercase:first-letter {
    text-transform: uppercase;
}

.box-account-total-amount {
    font-size: 12px;

    .ant-col {
        border-right: 1px solid #f1f1f1;

        &:last-of-type {
            border-right: none;
        }
    }

    @include lt-lg {
        .ant-col-xs-12 {
            &:nth-child(3),
            &:nth-child(4) {
                margin-top: 16px;
            }
        }
    }

    .ant-statistic {
        display: flex;
        align-items: center;
        flex-direction: column;

        .ant-statistic-title {
            color: $text-secondary-color;
            text-align: center;
        }
    }

    .account-sync-icon {
        position: absolute;
        top: -75px;
        right: 0;
        font-size: 18px;
        color: #7a7676;
    }
}

.empty-account {
    padding: 2em;
    color: red;
}

.font-12px {
    font-size: 12px;
}

.font-10px {
    font-size: 10px;
}

.h-scroll {
    overflow-x: auto;
}

.link--no-blue {
    color: inherit;
}

.has-comma {
    &:after {
        content: ',';
        display: inline;
        margin: 0 5px 0 0;
    }
}

.dirty-balance {
    background-color: #eb2f96;
    color: #fff;
    padding: 3px;
}

.form-item--no-label {
    margin-bottom: 10px;

    .ant-form-item-label {
        display: none;
    }
}

.img-cover {
    object-position: top center;
    object-fit: cover;
}

.transaction-remark {
    font-size: 0.7em;
    color: red;
    font-style: italic;
}

.voucher-invoice-attachment-list {
    overflow: hidden;

    .voucher-invoice-attachment {
        width: 90px;
        height: 90px;
        float: left;
        margin-right: 5px;
        margin-bottom: 5px;

        &:last-child {
            margin-right: 0;
        }

        .voucher-invoice-attachment-img {
            width: 100%;
            height: 100%;
        }
    }
}

.d-plex {
    display: flex;
}

.width-400px {
    width: 400px;
}

.fsz-14px {
    font-size: 14px;
}

.fsz-16px {
    font-size: 16px;
}

.fsz-18px {
    font-size: 18px;
}

.fsz-20px {
    font-size: 20px;
}

.fsz-24px {
    font-size: 24px;
}

.text-dark-color {
    color: $text-dark-color !important;
}

.text-dark-2 {
    color: $text-dark-color !important;
}

.text-secondary-color-2 {
    color: $text-secondary2-color !important;
}

.text-secondary-color {
    color: $text-secondary-color !important;
}

.text-error-color {
    color: $text-error-color !important;
}

.text-warning {
    color: $warning;
}

.gap-2 {
    gap: 8px;
}

.gap-3 {
    gap: 12px;
}

.gap-5 {
    gap: 32px;
}

//font-size
$class: 'font-size-';

@for $i from 0 through 60 {
    // for each $pd-t-#{i}
    .#{$class}#{$i} {
        font-size: #{$i}px;
    }
}

// align-item-list
$align-items: flex-start center flex-end;

@each $attr in $align-items {
    .align-items-#{$attr} {
        align-items: $attr;
    }
}

//justify-content
$justify-items: flex-start center flex-end space-between;

@each $attr in $justify-items {
    .justify-content-#{$attr} {
        display: flex;
        justify-content: $attr;
    }
}

//text-max-width
@for $i from 0 through 500 {
    .txt-maxW-#{$i} {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: #{$i}px;
    }
}

.whitespace-nowrap {
    white-space: nowrap;
}
