.a-table {
    &--responsive {
        width: 100%;
        overflow: auto;
    }
    &--font-small {
        table tbody {
            font-size: 12px;
        }
    }
}

.large-height-row {
    .ant-table-tbody {
        > tr {
            > td {
                height: calc(68px + 12px);
            }
        }
    }
}

.ant-table {
    .ant-table-thead > tr > th {
        border-bottom: none;
        background: #f6f6f6;
        color: #1a1a1a;
        padding: 5px 12px;
        white-space: nowrap;
    }

    .ant-table-container {
        table {
            > thead {
                > tr:first-child {
                    th:first-child {
                        border-top-left-radius: 4px;
                    }

                    th:last-child {
                        border-top-right-radius: 4px;
                    }
                }
            }

            .ant-table-row-expand-icon-cell {
                //display: flex;
                //padding-left: 6px;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none;
    }

    .ant-table-tbody {
        > tr {
            > td {
                border-color: #f1f1f1;
                padding: 6px 12px;

                &.ant-table-cell-row-hover {
                    background: #e5fce5;
                }
            }
            .ant-table-row-expand-icon-cell {
                > span {
                    @media screen and (max-width: 1281px) {
                        font-size: 1px;
                        > div {
                            width: 8px;
                        }
                    }
                }

                > span > div {
                    width: 12px;
                    color: $primary-color;
                    padding: 0 0 65px 0;
                }
                > div > i {
                    font-weight: 900;
                }
            }
        }
    }
    .ant-table-tbody > tr:last-child {
        > td {
            border-bottom: 1px solid transparent;
        }
    }

    .actions {
        width: 24px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
    }

    .ant-table-row:hover .actions {
        opacity: 1;
        transition: all 0.3s;
    }

    /* total width */
    ::-webkit-scrollbar {
        width: 4px;
    }

    /* background of the scrollbar except button or resizer */
    ::-webkit-scrollbar-track {
        background-color: #fff;
    }

    /* scrollbar itself */
    ::-webkit-scrollbar-thumb {
        border-radius: 16px;
        background-color: transparentize(#191919, 0.8);
        border: 6px solid rgba(25, 25, 25, 0.2);
    }

    /* set button(top and bottom of the scrollbar) */
    ::-webkit-scrollbar-button {
        display: none;
    }
}

.table-small {
    table {
        border-spacing: 0 10px;
    }

    .ant-table-tbody > tr > td {
        border-bottom: 1px solid transparent;
    }

    .ant-table-row-expand-icon-cell > div {
        padding: 4px;
        color: $primary-color;
    }

    .ant-table-row-level-0 {
        & > td {
            border-bottom: 1px solid transparent !important;
        }
    }

    .ant-table-wrapper::before,
    .ant-table-wrapper::after {
        display: none;
    }

    .ant-table-row-level-0:nth-child(odd) {
        td {
            //background: green;
        }
    }

    .ant-table-cell {
        border-radius: 0 0 6px 6px;
    }

    .ant-table-row {
        & > td:nth-child(1) {
            -webkit-border-radius: 6px 0 0 6px;
            border-radius: 6px 0 0 6px;
        }
        & > td:last-child {
            -webkit-border-radius: 0 6px 6px 0;
            border-radius: 0 6px 6px 0;
        }
    }
    .ant-checkbox-inner::after {
        border-spacing: initial;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        @media screen and (min-width: 992px) and (max-width: 1441px) {
            padding: 4px 6px;
        }
    }
    .ant-table .ant-table-expanded-row-fixed {
        position: relative;
        margin: -16px -16px;
        //padding: 10px 0;
        padding: 0 0 10px 0;
    }
}

.ant-table-pagination.ant-pagination {
    margin: 12px 0 0 0;

    &.hidden-change-page {
        .ant-pagination-prev,
        .ant-pagination-item,
        .ant-pagination-next {
            display: none;
        }
    }
}

.table-container {
    @include lt-md {
        .table-header {
            flex-direction: column;

            > div:first-child {
                margin-bottom: 8px;
            }
        }
    }
}

.ant-empty {
    margin-top: 16px;

    .ant-empty-image {
        height: 33px;
    }

    .ant-empty-description {
        color: #b1b1b1;
    }
}

.row-light {
    > td {
        border-bottom: 1px solid transparent;
    }
}
.row-dark {
    > td {
        background: #f6f6f6;
    }
}

.row-data {
    > td & :hover .actions {
        opacity: 1;
    }
}
