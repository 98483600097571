.device-actions {
  display: none;
}

.device-row-record:hover .device-actions {
  display: block;
}

.device-page {
  .table-container>.table-header {
    @media screen and (min-width: 576px) {
      display: flex;
      justify-content: space-between;
    }

    @media screen and (max-width: 575px) {
      .table-options {
        margin-top: 6px;
      }
    }
  }
}

.delete-connected-device {
  .warning {
    &-content {
      margin-top: 6px;
      padding: 4px;
      border-left: 2px solid #FDB924;
      display: flex;
      align-items: center;
    }

    &-icon {
      color: #FDB924;
      padding-left: 4px;

      >i {
        font-size: 18px;
      }
    }
  }
}

.status-dot {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 14px;
  background-color: $primary-color;

  &.offline {
    background-color: $text-error-color;
  }
}