@import '../../../resources/scss/mixins/mixins';

.payment-voucher {
    .ant-form {
            .label-width-medium,
            .label-width-large-medium,
            .label-width-large {
                .ant-form-item-label {
                    width: 132px;
                }
            }

        @include gt-md {
            .label-width-medium {
                .ant-form-item-label {
                    width: 92px;
                }
            }

            .label-width-large-medium {
                .ant-form-item-label {
                    width: 130px;
                }
            }

            .label-width-large {
                .ant-form-item-label {
                    width: 128px;
                }
            }
        }

        .status-col {
            .ant-form-item-label {
                flex: 0 0 100%;

                @include gt-sm {
                    flex: unset;
                }
            }
        }

        .label-width-medium {
            @media screen and (max-width: 1599px) {
                .ant-form-item-label {
                    width: 130px;
                }
            }
        }
    }

    .ant-card-head {
        border-radius: 4px;

        canvas {
            max-width: 234px;
        }
    }
}
