.access-denied {
  height: 100%;
  .body {
    height: 100%;
    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .content {
        padding-top: 4px;
        width: 400px;
        text-align: center;
      }
      .title {
        font-weight: 500;
        font-size: 24px;
        text-align: center;
      }
      .message {
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: #B1B1B1;
      }
    }
  }
}
