/*//Color*/
$color-base: #666;
$color-secondary: #222;
$color-text: #666;
$color-text-secondary: #888;
$color-text-heading :#222;
$color-link: #3498db;
$color-bg-footer: #282828;


$darkSkyBlue: #3498db;
$line: #cbcacf;
$title: #333;
$jadeGreen: #27ae60;
$brownishGrey: #666;
$bloodOrange: #ff4400;
$tomato: #e74c3c;
$btn: #adadad;


/*//Fonts-size*/
$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-m: 32px;


/*//Url base*/
$url-img : "../../images";

/*//Breakpoint*/
$bp-xs: 320px;
$bp-mobile: 320px;

$bp-s: 480px;
$bp-sm: 768px;
$bp-tablet: 768px;

$bp-m: 992px;
$bp-l: 1200px;
$bp-xl: 1440px;

/*// Contain floats / clearfix*/

%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

/* New style 2018-03-08 */
$base-color: #00ABF3;
$base-text-color: #fff;

// New style 2022-07-12
$primary-color: #27ae61;
$text-cuccess-color: #4DD69C;
$text-error-color: #FF4559;
$text-secondary-color: #5A5A5A;
$text-secondary2-color: #7D7E7E;
$text-dark-color: #1A1A1A;
$text-dark-color2: #1A1A1A;
$info-color: #339DFF;
$main-background-color: #f5f9f5;
$hover-row-color:#E5FCE5;
$warning: #FDB924;