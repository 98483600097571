.pending-voucher-container {
  .ant-card-head {
    border-bottom: none;
  }

  .ant-card-body {
    padding: 0;
  }
}

.payment-details {
  @include lt-md {
      .ant-card-head-wrapper {
          flex-direction: column;
          align-items: start;
      }

      .ant-card-head-title {
          padding: 4px 0;
          padding-bottom: 4px;
      }

      .ant-card-extra {
          padding: 0;
          margin-left: 0;
          
          .ant-col {
            margin-bottom: 8px;
          }
      }
  }
}
