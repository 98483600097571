@import '../../../resources/scss/variables/variable';
@import '../../../resources/scss/mixins/mixins';

.transaction-history-page {
  .search-form {
    padding: 12px;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        color: $text-dark-color;
      }
    }

    .ant-form-item {
      flex-wrap: nowrap;
    }

    @include xs {

      .ant-form .ant-form-item .ant-form-item-control {
        flex: 1;
        overflow: hidden;
      }

      .ant-form-item-label > label::after {
        content: ':';
        position: relative;
        top: -0.5px;
        margin: 0 8px 0 2px;
        display: block;
      }
    }

    .ant-input-group-compact {
      .ant-select-single {
        width: 30% !important;
      }
    }

    .people-and-status {

      @include lt-lg {
        flex-direction: column;

        .people-item {
          padding-right: 0;
        }

        .status-item {
          .ant-form-item-label {
            min-width: 140px;
          }
        }
      }
    }

    .item-overflow-hidden {
      .ant-form-item-control {
        overflow: hidden;
      }
    }
  }

  .actions {
    width: 24px;
    height: 100%;
  }

  .table-container {
    @media screen and (max-width: 1280px) {
      .ant-table {
        .ant-table-thead > tr > td {
          padding: 2px 4px;
        }
      }
    }

    th.ant-table-cell {
      &:last-child {
        width: 32px;
      }
    }

    .ant-table-row {
      &:hover {
        cursor: pointer;

        .actions {
          opacity: 1;
          transition: all 0.3s;
        }
      }
    }

    .ant-table-content {
      // overflow: auto;
    }

    .table-header {
      .action-btns {
        flex-wrap: wrap;

        @include gt-sm {
          justify-content: flex-end;
        }
      }
    }

    .participant {
      min-width: 60%;
      max-width: 60%;
    }
  }
}

.transaction-error {
  top: 0;
  position: relative;

  .ant-alert-error {
    height: 100%;
    padding: 6px 8px;
    background: #FFEDEF;
    border-top: 1px solid transparent;
    border-bottom: 1px solid #FFEDEF;
    border-right: 1px solid #FFEDEF;
    border-left: 1px solid #FFEDEF;
    -webkit-border-radius: 0 0 6px 6px;
    border-radius: 0 0 6px 6px;

    .ant-alert-message {
      color: #FF4559 !important;
      font-weight: 500 !important;
      font-size: 14px !important;
    }
  }
}
