@import '../../../resources/scss/mixins/mixins';

.receipt-voucher {
  .status-filter {
    .ant-form-item-label {
      flex: 0 0 100%;

      @include gt-sm {
        flex: unset;
      }
    }

    .ant-radio-wrapper {
      margin-right: 0;
    }

    @include gt-sm {
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .ant-form {
    .label-width-medium,
    .label-width-large-medium,
    .label-width-large {
      .ant-form-item-label {
        width: 132px;
      }
    }

    @include gt-md {
      .label-width-medium {
        .ant-form-item-label {
          width: 92px;
        }
      }

      .label-width-large-medium {
        .ant-form-item-label {
          width: 105px;
        }
      }

      .label-width-large {
        .ant-form-item-label {
          width: 128px;
        }
      }
    }

    .radio-group {
      @media screen and (max-width: 1599px) and ( min-width: 1025px) {
        .ant-form-item-label {
          width: 128px;
        }
      }

      @media screen and (max-width: 1024px) and (min-width: 800px) {
        .ant-form-item-label {
          width: 92px;
        }
      }

      @media screen and (max-width: 1280px){
        .ant-radio-wrapper {
            margin-right: -1px;
        }
      }
    }
  }
}
