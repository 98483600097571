.print-layout {
  -webkit-print-color-adjust: exact;

  .voucher-header {
    font-size: 1.8em;
    font-weight: bold;
    margin: -20px -20px 15px;
    padding: 20px;
    background-color: #737171;
    color: #fff;
  }

  .voucher-body {
    position: relative;
  }

  .voucher-title {
    font-size: 1.4em;
  }

  .voucher-date {
    font-size: 12px;
  }

  .voucher-qr-code {
    border: 1px solid #000;
    text-align: right;
    padding: 24px 10px;
    overflow: hidden;
    position: relative;

    img.qr-code {
      width: 60px;
      height: 60px;
      float: left;
    }

    .barcode {
      height: 60px;
      float: right;
    }

    .barcode-text {
      position: absolute;
      bottom: 0;
      right: 10px;
    }
  }

  .lien-luu {
    position: absolute;
    right: 10px;
    top: 6px;
  }

  .detail-item {
    overflow: hidden;
    margin-bottom: 10px;

    &--label {
      width: 200px;
      float: left;
      font-weight: bold;
    }

    &--value {
      float: left;
    }
  }

  .voucher-signed {
    &-column {
      text-align: center;
      width: 20%;
      float: left;
    }
  }
}