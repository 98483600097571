.ant-popover {
    .ant-popover-inner {
        border-radius: 4px;

        .ant-popover-title {
            min-height: 48px;
            text-transform: capitalize;
            font-size: 16px;
            display: flex;
            align-items: center;
        }
    }

    &.max-width-350 {
        .ant-popover-inner {
            max-width: 350px;
        }
    }

    &.no-padding {
        .ant-popover-inner-content {
            padding: 0;
        }
    }

    .ant-popover-inner-content {
        .ant-menu {
            .ant-menu-item {
                margin: 0;
                color: #5A5A5A;

                &:hover {
                  color: $primary-color;
                  background-color: #dfede3;
                }

                .ant-menu-title-content {
                    text-transform: capitalize;

                    i {
                        margin-right: 4px;
                    }
                }
            }
        }
    }
}
