.ant-dropdown {
  .ant-dropdown-menu {
    padding: 0;
  }

  .ant-dropdown-menu-item {
    border-bottom: 1px solid #F1F1F1;
    height: 36px;

    .ant-dropdown-menu-title-content {

      a,
      span {
        text-transform: capitalize;
        color: $text-secondary-color;

        &:hover {
          color: $primary-color;
        }
      }

      i.fa-solid {
        width: 24px;
        padding-right: 4px;
        justify-content: center;
      }
    }
  }
}

.ant-select-multiple .ant-select-selection-item {
  border-radius: 16px;
  border: 1px solid var(--greyscale-new-border, #C0C0C0);
  background: var(--greyscale-new-white, #FFF);
  padding: 0 10px 0 12px;
  font-size: 12px;
  -webkit-margin-end: 4px;
  margin-inline-end: 8px;
  margin-top: 4px;
  margin-bottom: 4px;

  .ant-select-selection-item-content {
    margin-right: 6px;
  }
}